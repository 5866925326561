
  import Vue from 'vue'

  import FileInput from './FileInput.vue'
  import {UploadSuppObj} from '@/types'

  export default Vue.extend({
    name: 'UploadSupplements',
    components: {FileInput},
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isUploading: false,
        files: [] as any,
        progress: 0,
        state: '',
        downloadLink: null,
        validateCompany: 'required|includesInvalidChars:\\,/,:,*,?,",<,>',
        uploadObj: {
          publicationDate: null,
          company: '',
          supplementTitle: '',
          name: '',
          email: '',
          phone: '',
          fileId: null
        } as UploadSuppObj
      }
    },
    computed: {
      publicationDateFormatted(): string {
        if (this.uploadObj.publicationDate) {
          const d = new Date(this.uploadObj.publicationDate)
          const day = d.getDate()
          const month = d.getMonth() + 1
          const year = d.getFullYear()
          return `${day > 9 ? day : `0${day}`}.${month > 9 ? month : `0${month}`}.${year}`
        }
        return ''
      }
    },

    methods: {
      async submit(): Promise<void> {
        // make sure that all fields will be validated at the same time
        const isValid1 = await (this.$refs.form as HTMLFormElement).validate()
        const isValid2 = await this.$validator.validateAll()

        if (!(isValid1 && isValid2)) {
          return
        }

        let formData = new FormData()
        formData.append('file', this.files[0])
        formData.append('upload', JSON.stringify(this.uploadObj))
        this.isUploading = true
        this.progress = 0
        this.state = ''

        try {
          this.downloadLink = await this.$store.call('upload-supplements', {
            data: formData,
            params: {
              companyId: this.$route.params.companyId,
              categoryId: this.product.categoryId,
              productId: this.product.id
            },
            onProgress: (e: ProgressEvent) => {
              this.progress = (e.loaded / e.total) * 100
            }
          })
          this.state = 'success'
        } catch (e) {
          this.$eventBus.setError('Ihre Daten konnten nicht hochgeladen werden, bitte überprüfen Sie diese.')
        }

        this.isUploading = false
      }
    }
  })
