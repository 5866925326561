import Store from 'vue-powernap'

import Category from './category'
import Company from './company'
import File from './file'
import FixedFormat from './fixed-format'
import Link from './link'
import Product from './product'
import ProductFile from './product-file'

// store
const store = new Store()

// models
const category = new Category()
const company = new Company()
const file = new File()
const fixedFormat = new FixedFormat()
const link = new Link()
const product = new Product()
const productFile = new ProductFile()

// registration
store.register(company)
company.register(category)
category.register(product)
product.register(file)
product.register(fixedFormat)

store.register(productFile)
store.register(link)

// actions

store.addAction('upload-file', async ({ data }) => {
  const headers = Object.assign({}, store.defaultHeaders)
  delete headers['Content-Type']

  const response = await fetch('/api/files', {
    method: 'POST',
    body: data,
    headers: headers
  })
  if (!response.ok) {
    throw response
  }

  return response.json()
})

store.addAction('update-file', async ({ id, data }) => {
  const headers = Object.assign({}, store.defaultHeaders)
  delete headers['Content-Type']

  const response = await fetch(`/api/files/${id}`, {
    method: 'PUT',
    body: data,
    headers: headers
  })
  if (!response.ok) {
    throw response
  }

  return response.json()
})

store.addAction('delete-file', async ({ id }) => {
  const response = await fetch(`/api/files/${id}`, {
    method: 'DELETE'
  })

  if (!response.ok) {
    throw response
  }
})

store.addAction('upload-product', async ({ data, params, onProgress }) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `/api/companies/${params.companyId}/product-categories/${params.categoryId}/products/${params.productId}/uploads`)
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const contentLocation = xhr.getResponseHeader('Content-Location')
        resolve(contentLocation)
      } else {
        reject(xhr.statusText)
      }
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.upload.onprogress = onProgress
    xhr.send(data)
  })
})

store.addAction('upload-supplements', async ({ data, params, onProgress }) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `/api/companies/${params.companyId}/product-categories/${params.categoryId}/products/${params.productId}/uploads/supplements`)
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const contentLocation = xhr.getResponseHeader('Content-Location')
        resolve(contentLocation)
      } else {
        reject(xhr.statusText)
      }
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.upload.onprogress = onProgress
    xhr.send(data)
  })
})

export default store
